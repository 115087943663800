import React from 'react';
import { hot } from 'react-hot-loader/root';
import CapacitorDischargeModule from './capacitor-logic';
import styles from './capacitor.module.css';

function CapacitorDischargeComponent({ capacitorDischarge }: { capacitorDischarge: CapacitorDischargeModule }) {
  function handleDown() {
    capacitorDischarge.charge();
  }
  function handleUp() {
    capacitorDischarge.stopCharge();
  }

  return (
    <div className={styles.container}>
      <button
        onMouseDown={handleDown}
        onMouseLeave={handleUp}
        onMouseUp={handleUp}
        onTouchStart={handleDown}
        onTouchEnd={handleUp}
      >
        (the lever)
      </button>
    </div>
  );
}

export default hot(CapacitorDischargeComponent);
