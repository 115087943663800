import component from './KeypadsComponent';
import logic from './keypads-logic';

export default {
  name: 'Keypads',
  component,
  logic,
}

export * from './keypads-types';
