import { BombModule } from "../../module-sdk";
import { KEYPAD_SEQUENCES } from './keypads-data';
import { randomOf, randomInt } from "@reverse/random";
import { KeypadKey } from "./keypads-types";

class KeypadsModule extends BombModule {
  public keypads: KeypadKey[] = [];

  constructor() {
    super();
    const selectedSequence = randomOf(KEYPAD_SEQUENCES);

    do {
      let index = randomInt(0, selectedSequence.length - 1);

      const existingIndexes = this.keypads.map((keypad) => {
        return keypad.index;
      });

      if (existingIndexes.includes(index)) {
        do {
          index = randomInt(0, selectedSequence.length - 1);
        } while (existingIndexes.includes(index));
      }

      this.keypads.push({
        symbol: selectedSequence[index],
        disarmed: false,
        index
      });

      selectedSequence.slice(index, 1);
    } while (this.keypads.length !== 4);
  }

  press(index: number) {
    const amountDisarmed = this.keypads.filter((keypad) => {
      return keypad.disarmed === true;
    }).length;
    const keypadOrder = this.keypads.concat().sort((a, b) => {
      return a.index - b.index;
    });

    if (this.keypads[index].disarmed || this.keypads[index].symbol === keypadOrder[amountDisarmed].symbol) {
      this.keypads[index].disarmed = true;
    } else {
      this.strike();
    }

    const disarmedCheck = this.keypads.map((keypad) => {
      return keypad.disarmed;
    });

    if (disarmedCheck.every((keypadIsDisarmed) => keypadIsDisarmed === true)) {
      this.disarm();
    }
  }
}

export default KeypadsModule;
