import { randomOf } from "@reverse/random";
import { formatTime } from "../../utils/format-time";
import { ButtonColor, ButtonLabel, ButtonStripColor, ALL_BUTTON_LABELS, ALL_BUTTON_COLORS, ALL_BUTTON_STRIP_COLORS } from "./button-types";
import { BombModule } from "../../module-sdk";

class Button extends BombModule {
  public color: ButtonColor;
  public label: ButtonLabel;
  public isHoldingDown: boolean;
  public stripColor: ButtonStripColor | null;

  constructor() {
    super();

    this.color = randomOf(ALL_BUTTON_COLORS);
    this.label = randomOf(ALL_BUTTON_LABELS);
    this.isHoldingDown = false;
    this.stripColor = null;
  }

  pressButton() {
    const batteries = this.bomb.batteries.total;
    if (batteries > 2 && this.label === 'detonate') {
      this.disarm();
    } else if (this.label === 'hold' && this.color === 'red') {
      this.disarm();
    } else if (batteries > 3 && this.bomb.indicators.has('frk')) {
      if (
        (this.label === 'abort' && this.color === 'red')
        || (this.label === 'abort' && this.color === 'white' && !this.bomb.indicators.has('car'))
        || (this.label === 'abort' && this.color === 'yellow')
        || (this.label === 'hold' && this.color === 'blue')
        || (this.label === 'hold' && this.color === 'white' && !this.bomb.indicators.has('car'))
        || (this.label === 'hold' && this.color === 'yellow')
        || (this.label === 'press' && this.color === 'red')
        || (this.label === 'press' && this.color === 'blue')
        || (this.label === 'press' && this.color === 'white' && !this.bomb.indicators.has('car'))
        || (this.label === 'press' && this.color === 'yellow')
      ) {
        this.disarm();
      }
    } else {
      this.strike();
    }
  }

  startHold() {
    this.isHoldingDown = true;
    this.stripColor = randomOf(ALL_BUTTON_STRIP_COLORS);
  }

  releaseHold() {
    const batteries = this.bomb.batteries.total;

    if (
      (this.label === 'abort' && this.color === 'blue')
      || (this.label === 'abort' && this.color === 'white' && this.bomb.indicators.has('car'))
      || (this.label === 'detonate' && batteries < 2)
      || (this.label === 'hold' && this.color === 'white' && this.bomb.indicators.has('car'))
      || (this.label === 'press' && this.color === 'white' && this.bomb.indicators.has('car'))
    ) {
      if (this.stripColor === 'blue' && formatTime(this.bomb.time).split('').includes('4')) {
        this.disarm();
      } else if (this.stripColor === 'yellow' && formatTime(this.bomb.time).split('').includes('5')) {
        this.disarm();
      } else if (this.stripColor === 'white' && formatTime(this.bomb.time).split('').includes('1')) {
        this.disarm();
      } else {
        this.strike();
      }
    } else if (!(batteries > 3 && this.bomb.indicators.has('frk'))) {
      if (
        (this.label === 'abort' && this.color === 'red')
        || (this.label === 'abort' && this.color === 'white' && !this.bomb.indicators.has('car'))
        || (this.label === 'abort' && this.color === 'yellow')
        || (this.label === 'hold' && this.color === 'blue')
        || (this.label === 'hold' && this.color === 'white' && !this.bomb.indicators.has('car'))
        || (this.label === 'hold' && this.color === 'yellow')
        || (this.label === 'press' && this.color === 'red')
        || (this.label === 'press' && this.color === 'blue')
        || (this.label === 'press' && this.color === 'white' && !this.bomb.indicators.has('car'))
        || (this.label === 'press' && this.color === 'yellow')
      ) {
        if (this.stripColor === 'blue' && formatTime(this.bomb.time).split('').includes('4')) {
          this.disarm();
        } else if (this.stripColor === 'yellow' && formatTime(this.bomb.time).split('').includes('5')) {
          this.disarm();
        } else if (this.stripColor === 'white') {
          this.disarm();
        } else {
          this.strike();
        }
      } else {
        this.strike();
      }
    } else {
      this.strike();
    }

    this.isHoldingDown = false;
    this.stripColor = null;
  }
}

export default Button;
