import component from './VentingGasComponent';
import logic from './venting-gas-logic';

export default {
  name: 'Venting Gas',
  component,
  logic,
}

export * from './venting-gas-types';
