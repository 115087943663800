import { Tuple } from "../../utils/tuple";

// TYPE DEFS
export const ALL_BUTTON_COLORS = Tuple('red', 'blue', 'yellow', 'white');
export const ALL_BUTTON_STRIP_COLORS = Tuple('yellow', 'blue', 'white');
export const ALL_BUTTON_LABELS = Tuple('abort', 'detonate', 'hold', 'press');

export type ButtonColor = typeof ALL_BUTTON_COLORS[number];
export type ButtonStripColor = typeof ALL_BUTTON_STRIP_COLORS[number];
export type ButtonLabel = typeof ALL_BUTTON_LABELS[number];
