// This defines all Modules in the game. A lot of types are derived from this file.
// export { default as template } from './template';

// Normal Modules
export { default as button } from './button';
export { default as complicatedWires } from './complicated-wires';
export { default as keypads } from './keypads';
export { default as memory } from './memory';
export { default as mazes } from './mazes';
export { default as morseCode } from './morse-code';
export { default as passwords } from './passwords';
export { default as simonSays } from './simon-says';
export { default as whosOnFirst } from './whos-on-first';
export { default as wireSequences } from './wire-sequences';
export { default as wires } from './wires';

// Needy Modules
export { default as capacitorDischarge } from './capacitor-discharge';
export { default as knobs } from './knobs';
export { default as ventingGas } from './venting-gas';
