import component from './WiresComponent';
import logic from './wires-logic';

export default {
  name: 'Wires',
  component,
  logic,
}

export * from './wires-types';
