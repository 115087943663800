import React from 'react'
import { NeedyModule } from '../module-sdk';
import clsx from 'clsx';
import styles from '../css/needy-plate.module.css';

function NeedyModulePlate({ module, children }: { module: NeedyModule, children: JSX.Element }) {
  return (
    <div
      className={clsx(styles.module)}
    >
      <div>
        TIME: {module.time === -1 ? '[unactivated]' : module.time}
      </div>
      { children }
    </div>
  )
}

export default NeedyModulePlate;
