import { BombModule } from "../../module-sdk";
import { SimonColor, ALL_SIMON_SAYS_COLORS, SimonColorMap } from "./simon-types";
import { randomOf, randomInt } from "@reverse/random";

class SimonSaysModule extends BombModule {
  public sequences: number;
  public sequence: SimonColor[];
  public roundClicked: number;

  constructor() {
    super();

    // Run your generation code here
    this.sequences = randomInt(3, 5);
    this.roundClicked =0;
    this.sequence = [];
    this.sequence.push(randomOf(ALL_SIMON_SAYS_COLORS));
  }

  pressButton(color: SimonColor) {
    if(this.disarmed) {
      this.strike();
      return;
    }

    // Get some bomb metadata
    const strikes = this.bomb.strikes;
    const containsVowel = /^[aeiou]$/i.test(this.bomb.serial);

    // Get the thing we should be clicking.
    const nextFlashing = this.sequence[this.roundClicked];

    // Create the Lookup Map
    let map: SimonColorMap = null as any;
    if(containsVowel) {
      if(strikes === 0) {
        map = {
          blue: 'red',
          red: 'blue',
          yellow: 'green',
          green: 'yellow',
        };
      } else if (strikes === 1) {
        map = {
          blue: 'green',
          red: 'yellow',
          yellow: 'red',
          green: 'blue',
        };
      } else if (strikes === 2) {
        map = {
          blue: 'red',
          red: 'green',
          yellow: 'blue',
          green: 'yellow',
        };
      }
    } else {
      if (strikes === 0) {
        map = {
          blue: 'yellow',
          red: 'blue',
          yellow: 'red',
          green: 'green',
        };
      } else if (strikes === 1) {
        map = {
          blue: 'blue',
          red: 'red',
          yellow: 'green',
          green: 'yellow',
        };
      } else if (strikes === 2) {
        map = {
          blue: 'green',
          red: 'yellow',
          yellow: 'red',
          green: 'blue',
        };
      }
    }

    // Check if the lookup map matches
    if(map[color] === nextFlashing) {
      // up the index
      this.roundClicked++;
      // if we reached the end
      if (this.roundClicked === this.sequence.length) {
        // reset the index to 0
        this.roundClicked = 0;
        // disarm the bomb if that was the max sequence length
        if (this.sequence.length === this.sequences) {
          this.disarm();
          this.sequence = [];
        } else {
          // add a new color.
          this.sequence.push(randomOf(ALL_SIMON_SAYS_COLORS));
        }
      }
    } else {
      this.strike();
    }
  }
}

export default SimonSaysModule;
