import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader/root';
import MazesModule from './mazes-logic';
import styles from './mazes.module.css';

function MazesComponent({ mazes }: { mazes: MazesModule }) {
  return (
    <div className={styles.container}>
      <p>
        Marker 1: ({mazes.maze.marker1[1]}, {mazes.maze.marker1[0]})
      </p>
      <p>
        Marker 2: ({mazes.maze.marker2[1]}, {mazes.maze.marker2[0]})
      </p>
      <p>
        You: ({mazes.player[1]}, {mazes.player[0]})
      </p>
      <p>
        Goal: ({mazes.goal[1]}, {mazes.goal[0]})
      </p>
      <div style={{ display: 'flex', width: '100px' }}>
        <button style={{ flex: 1 }} onClick={useCallback(() => mazes.up(), [mazes])}>up</button><br/>
      </div>
      <div style={{ display: 'flex', width: '100px'}}>
        <button style={{flex:1}} onClick={useCallback(() => mazes.left(), [mazes])}>left</button>
        <button style={{flex:1}} onClick={useCallback(() => mazes.right(), [mazes])}>right</button><br />
      </div>
      <div style={{ display: 'flex', width: '100px' }}>
        <button style={{flex:1}} onClick={useCallback(() => mazes.down(), [mazes])}>down</button>
      </div>
    </div>
  );
}

export default hot(MazesComponent);
