import component from './MemoryComponent';
import logic from './memory-logic';

export default {
  name: 'Memory',
  component,
  logic,
}

export * from './memory-types';
