import { WOFButtonLabel, WOFPosition, WOFDisplayLabel } from "./wof-types";

export const DISPLAY_MAP: { [Label in WOFDisplayLabel]: WOFPosition } = {
  ' ': 'bl',
  'blank': 'mr',
  'c': 'tr',
  'cee': 'br',
  'display': 'br',
  'first': 'tr',
  'hold on': 'br',
  'lead': 'br',
  'led': 'ml',
  'leed': 'bl',
  'no': 'br',
  'nothing': 'ml',
  'okay': 'tr',
  'read': 'mr',
  'red': 'mr',
  'reed': 'bl',
  'says': 'br',
  'see': 'br',
  'their': 'mr',
  'there': 'br',
  'they are': 'ml',
  'they\'re': 'bl',
  'ur': 'tl',
  'yes': 'ml',
  'you': 'mr',
  'you are': 'br',
  'your': 'mr',
  'you\'re': 'mr'
};
export const BUTTON_LIST: { [Label in WOFButtonLabel]: WOFButtonLabel[] } = {
  'blank': ['wait', 'right', 'okay', 'middle', 'blank'],
  'done': ['sure', 'uh huh', 'next', 'what?', 'your', 'ur', 'you\'re', 'hold', 'like'],
  'first': ['left', 'okay', 'yes', 'middle', 'no', 'right', 'nothing', 'uhhh', 'wait'],
  'hold': ['you are', 'u', 'done', 'uh uh', 'you', 'ur', 'sure', 'what?', 'you\'re'],
  'left': ['right', 'left'],
  'like': ['you\'re', 'next', 'u', 'ur', 'hold', 'done', 'uh uh', 'what?', 'uh huh'],
  'middle': ['blank', 'ready', 'okay', 'what', 'nothing', 'press', 'no', 'wait', 'left'],
  'next': ['what?', 'uh huh', 'uh uh', 'your', 'hold', 'sure', 'next'],
  'no': ['blank', 'uhhh', 'wait', 'first', 'what', 'ready', 'right', 'yes', 'nothing'],
  'nothing': ['uhhh', 'right', 'okay', 'middle', 'yes', 'blank', 'no', 'press', 'left'],
  'okay': ['middle', 'no', 'first', 'yes', 'uhhh', 'nothing', 'wait', 'okay'],
  'press': ['right', 'middle', 'yes', 'ready', 'press'],
  'ready': ['yes', 'okay', 'what', 'middle', 'left', 'press', 'right', 'blank', 'ready'],
  'right': ['yes', 'nothing', 'ready', 'press', 'no', 'wait', 'what', 'right'],
  'sure': ['you are', 'done', 'like', 'you\'re', 'you', 'hold', 'uh huh', 'ur', 'sure'],
  'u': ['uh huh', 'sure', 'next', 'what?', 'you\'re', 'ur', 'uh huh', 'done', 'u'],
  'uhhh': ['ready', 'nothing', 'left', 'what', 'okay', 'yes', 'right', 'no', 'press'],
  'uh huh': ['uh huh'],
  'uh uh': ['ur', 'u', 'you are', 'you\'re', 'next', 'uh uh'],
  'ur': ['done', 'u', 'ur'],
  'wait': ['uhhh', 'no', 'blank', 'okay', 'yes', 'left', 'first', 'press', 'what'],
  'what': ['uhhh', 'what'],
  'what?': ['you', 'hold', 'you\'re', 'your', 'u', 'done', 'uh uh', 'like', 'you are'],
  'yes': ['okay', 'right', 'uhhh', 'middle', 'first', 'what', 'press', 'ready', 'nothing'],
  'you': ['sure', 'you are', 'your', 'you\'re', 'next', 'uh huh', 'ur', 'hold', 'what?'],
  'you\'re': ['you', 'you\'re'],
  'you are': ['your', 'next', 'like', 'uh huh', 'what?', 'done', 'uh uh', 'hold', 'you'],
  'your': ['uh uh', 'you are', 'uh huh', 'your']
};
