import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader/root';
import MorseCodeModule from './morse-logic';
import MorseCodeFlasher from './MorseCodeFlasher';
// import styles from './mores-code.module.css';

function MorseCodeComponent({ morseCode }: { morseCode: MorseCodeModule }) {
  return (
    <div>
      <h1>Morse Code</h1>

      <MorseCodeFlasher word={morseCode.word} disarmed={morseCode.disarmed} />      

      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <button onClick={useCallback(() => morseCode.decreaseFreq(), [morseCode])}>{'<'}</button>
          &nbsp;{morseCode.getFreqString()} MHz&nbsp;
          <button onClick={useCallback(() => morseCode.increaseFreq(), [morseCode])}>{'>'}</button>
        </div>
        <button onClick={useCallback(() => morseCode.transmit(), [morseCode])} style={{ marginTop: '1em' }}>TX</button>
      </div>
    </div>
  )
}

export default hot(MorseCodeComponent);
