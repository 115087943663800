import { randomOf } from '@reverse/random';
import { shuffle, remove } from '@reverse/array';

import { BombModule } from '../../module-sdk';
import { PasswordsLetter, ALL_PASSWORDS_PASSWORDS, ALL_PASSWORDS_LETTERS, PasswordsWord } from './passwords-types';

class PasswordsModule extends BombModule {
  public password: PasswordsWord;
  public letters: string[][] = [[], [], [], [], []];
  public selectedIndexes: number[] = [0, 0, 0, 0, 0];

  constructor() {
    super();

    // Choose a password
    this.password = randomOf(ALL_PASSWORDS_PASSWORDS);
    // Put each letter in
    this.password.split('').forEach((letter, index) => {
      this.letters[index].push(letter as PasswordsLetter);
    });
    // Fill in the rest of the arrays, making sure NOT to duplicate any.
    this.letters.forEach((array, i) => {
      for (let i = 0; i < 5; i++) {
        const validLetters = ALL_PASSWORDS_LETTERS.filter(letter => !array.includes(letter));
        array.push(randomOf(validLetters));
      }
    });
    // Check all other passwords to ensure that there are no matches
    let bannedLetters: string[][] = [[],[],[],[],[]];
    ALL_PASSWORDS_PASSWORDS.filter(x => x !== this.password).forEach((password) => {
      // If the password is possible.
      if(
        this.letters[0].includes(password.charAt(0))
        && this.letters[1].includes(password.charAt(1))
        && this.letters[2].includes(password.charAt(2))
        && this.letters[3].includes(password.charAt(3))
        && this.letters[4].includes(password.charAt(4))
      ) {
        // Remove one of the letters, and make sure that the original is still an option
        const options = password
          .split('')
          .map((char, index) => ({index, char, allowed: char !== this.password.charAt(index)}))
          .filter(item => item.allowed);

        const option = randomOf(options);

        // ban the letter from generation
        bannedLetters[option.index].push(option.char);
        const validLetters = ALL_PASSWORDS_LETTERS
          .filter(letter => !this.letters[option.index].includes(letter))
          .filter(letter => !bannedLetters[option.index].includes(letter));
        this.letters[option.index] = remove(this.letters[option.index], option.char).concat(randomOf(validLetters));
      }
    });

    console.log(this.password);
    console.log(this.letters);

    this.letters = this.letters.map((array) => {
      return shuffle(array);
    })
  }

  handleUp(index: number) {
    if(this.selectedIndexes[index] === 0) {
      this.selectedIndexes[index] = 5;
    }else {
      this.selectedIndexes[index]--;
    }
  }
  handleDown(index: number) {
    if(this.selectedIndexes[index] === 5) {
      this.selectedIndexes[index] = 0;
    }else {
      this.selectedIndexes[index]++;
    }
  }
  handleSubmit() {
    const enteredPassword = this.selectedIndexes.map((selected, i) => {
      return this.letters[i][selected];
    }).join('');

    if(enteredPassword === this.password) {
      this.disarm();
    }else {
      this.strike();
      console.log(this.password)
    }
  }
}

export default PasswordsModule;
