import { BombModule } from "../../module-sdk";
import { MAZES_DATA } from "./mazes-data";
import { randomOf, randomInt } from "@reverse/random";
import { Maze, MazePosition } from "./mazes-types";

class MazesModule extends BombModule {
  public maze: Maze;
  public goal: MazePosition;
  public player: MazePosition;

  constructor() {
    super();

    this.maze = randomOf(MAZES_DATA);
    this.goal = [randomInt(0, 5), randomInt(0, 5)];
    do {
      this.player = [randomInt(0, 5), randomInt(0, 5)];
    } while (this.player[0] === this.goal[0] && this.player[1] === this.goal[1]);
  }

  private checkPosition() {
    if (this.player[0] === this.goal[0] && this.player[1] === this.goal[1]) {
      this.disarm();
    }
  }

  up() {
    if(this.disarmed) return;
    const [y, x] = this.player;
    if (y === 0) { return; }
    if (this.maze.tiles[y][x].top) {
      this.strike();
      return;
    };
    this.player[0]--;

    this.checkPosition();
  }
  down() {
    if (this.disarmed) return;
    const [y, x] = this.player;
    if (y === 5) { return; }
    if (this.maze.tiles[y][x].bottom) {
      this.strike();
      return;
    };
    this.player[0]++;

    this.checkPosition();
  }
  left() {
    if (this.disarmed) return;
    const [y, x] = this.player;
    if (x === 0) { return; }
    if (this.maze.tiles[y][x].left) {
      this.strike();
      return;
    };
    this.player[1]--;

    this.checkPosition();
  }
  right() {
    if (this.disarmed) return;
    const [y, x] = this.player;
    if (x === 5) { return; }
    if (this.maze.tiles[y][x].right) {
      this.strike();
      return;
    };
    this.player[1]++;

    this.checkPosition();
  }
}

export default MazesModule;
