import component from './MorseCodeComponent';
import logic from './morse-logic';

export default {
  name: 'Morse Code',
  component,
  logic,
}

export * from './morse-types';
