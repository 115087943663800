import { LedConfiguration } from "./knobs-types";

export const ALL_KNOB_PATTERNS: LedConfiguration[] = [
  {
    lights: [
      [false, false, true, false, true , true],
      [true, true, true, true, false, true]
    ],
    position: 'up'
  },
  {
    lights: [
      [true, false, true, false, true , false],
      [false, true, true, false, true, true]
    ],
    position: 'up'
  },
  {
    lights: [
      [false, true, true, false, false , true],
      [true, true, true, true, false, true]
    ],
    position: 'down'
  },
  {
    lights: [
      [true, false, true, false, true , false],
      [false, true, false, false, false, true]
    ],
    position: 'down'
  },
  {
    lights: [
      [false, false, false, false, true , false],
      [true, false, false, true, true, true]
    ],
    position: 'left'
  },
  {
    lights: [
      [false, false, false, false, true , false],
      [false, false, false, true, true, false]
    ],
    position: 'left'
  },
  {
    lights: [
      [true, false, true, true, true , true],
      [true, true, true, false, true, false]
    ],
    position: 'right'
  },
  {
    lights: [
      [true, false, true, true, false , false],
      [true, true, true, false, true, false]
    ],
    position: 'right'
  }
];
