import component from './SimonSaysComponent';
import logic from './simon-logic';

export default {
  name: 'Simon Says',
  component,
  logic,
}

export * from './simon-types';
