import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader/root';
import { capitalize } from '@reverse/string';

import KnobsModule from './knobs-logic';
import styles from './knobs.module.css';

function KnobsComponent({ knobs }: { knobs: KnobsModule }) {
  const handleRotateKnob = useCallback(() => knobs.rotateKnob(), [knobs]);

  return (
    <div className={styles.container}>
      <h1>Knobs</h1>
      <p>Up Direction: {capitalize(knobs.upDirection)}</p>
      <button onClick={handleRotateKnob}>Rotate (Direction: {capitalize(knobs.direction)})</button>

      <br />

      <table style={{
        border: '1px solid black'
      }}>
        <tbody>
          {
            knobs.lights
            ? knobs.lights.lights.map((lightRow) => {
              return (
                <tr>
                  {
                    lightRow.map((light) => {
                      return <td>{light ? 'On' : 'Off'}</td>
                    })
                  }
                </tr>
              )
            })
            : <>
              <tr>
                <td>Off</td>
                <td>Off</td>
                <td>Off</td>
                <td>Off</td>
                <td>Off</td>
                <td>Off</td>
              </tr>
              <tr>
              <td>Off</td>
              <td>Off</td>
              <td>Off</td>
              <td>Off</td>
              <td>Off</td>
              <td>Off</td>
              </tr>
            </>
          }
        </tbody>
      </table>
    </div>
  );
}

export default hot(KnobsComponent);
