import { BombModule } from "../../module-sdk";
import { MemoryButtonLabel, MemoryRound, ALL_MEMORY_BUTTON_LABELS, ALL_MEMORY_DISPLAY_LABELS, MemoryPosition } from "./memory-types";
import { randomOf } from "@reverse/random";
import { shuffle } from "@reverse/array";

class MemoryModule extends BombModule {
  public sequences: MemoryRound[];
  public currentRound: number;

  constructor() {
    super();

    this.sequences = [];
    this.currentRound = 0;

    this.reset();
  }

  reset() {
    this.sequences = [];
    this.sequences.push({
      display: randomOf(ALL_MEMORY_DISPLAY_LABELS),
      buttons: shuffle(ALL_MEMORY_BUTTON_LABELS),
      clicked: null,
      clickedPosition: null,
    });
    this.currentRound = 0;
  }

  click(label: MemoryButtonLabel) {
    const currentRound: MemoryRound = this.sequences[this.currentRound];
    const positionClicked: MemoryPosition = currentRound.buttons.indexOf(label) as MemoryPosition;
    const labelClicked: MemoryButtonLabel = currentRound.buttons[positionClicked];

    if (this.currentRound === 0) {
      if (!(
        (currentRound.display === '1' && positionClicked === 1)
        || (currentRound.display === '2' && positionClicked === 1)
        || (currentRound.display === '3' && positionClicked === 2)
        || (currentRound.display === '4' && positionClicked === 3)
      )) {
        this.strike();
        this.reset();
      }
    } else if (this.currentRound === 1) {
      if (!(
        (currentRound.display === '1' && labelClicked === '4')
        || (currentRound.display === '2' && this.sequences[0].clickedPosition === 0)
        || (currentRound.display === '3' && positionClicked === 0)
        || (currentRound.display === '4' && this.sequences[0].clickedPosition === 0)
      )) {
        this.strike();
        this.reset();
      }
    } else if (this.currentRound === 2) {
      if (!(
        (currentRound.display === '1' && this.sequences[1].clicked === labelClicked)
        || (currentRound.display === '2' && this.sequences[0].clicked === labelClicked)
        || (currentRound.display === '3' && positionClicked === 2)
        || (currentRound.display === '4' && labelClicked === '4')
      )) {
        this.strike();
        this.reset();
      }
    } else if (this.currentRound === 3) {
      if (!(
        (currentRound.display === '1' && this.sequences[0].clickedPosition === positionClicked)
        || (currentRound.display === '2' && positionClicked === 0)
        || (currentRound.display === '3' && this.sequences[1].clickedPosition === positionClicked)
        || (currentRound.display === '4' && this.sequences[1].clickedPosition === positionClicked)
      )) {
        this.strike();
        this.reset();
      }
    } else if (this.currentRound === 4) {
      if (!(
        (currentRound.display === '1' && this.sequences[0].clicked === labelClicked)
        || (currentRound.display === '2' && this.sequences[1].clicked === labelClicked)
        || (currentRound.display === '3' && this.sequences[3].clicked === labelClicked)
        || (currentRound.display === '4' && this.sequences[2].clicked === labelClicked)
      )) {
        this.strike();
        this.reset();
      }
    }

    this.sequences[this.currentRound].clickedPosition = positionClicked;
    this.sequences[this.currentRound].clicked = labelClicked;

    if (this.sequences.length === 5) {
      this.disarm();
    } else {
      this.currentRound++;

      const newSequence: MemoryRound = {
        display: randomOf(ALL_MEMORY_DISPLAY_LABELS),
        buttons: shuffle(ALL_MEMORY_BUTTON_LABELS),
        clicked: null,
        clickedPosition: null
      }

      this.sequences.push(newSequence);
    }
  }
}

export default MemoryModule;
