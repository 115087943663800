import React from 'react';
import { hot } from 'react-hot-loader/root';
import MemoryModule from './memory-logic';
import { MemoryButtonLabel } from './memory-types';
// import styles from './memory.module.css';

function MemoryButton({ label, memory }: { label: MemoryButtonLabel, memory: MemoryModule }) {
  function click() {
    memory.click(label);
  }

  return <div onClick={click} style={{ width: '15%', textAlign: 'center', height: '100px', lineHeight: '100px', backgroundColor: '#DDCEB9', color: '#2A261D', fontSize: '60px', fontWeight: 'bold', borderRadius: '10px', margin: '0px 2px' }}>
    {label}
  </div>
}

function MemoryComponent({ memory }: { memory: MemoryModule }) {
  return (
    <>
      <h1>Memory</h1>
      <p>Round {memory.sequences.length} of 5.</p>
    
      <div>
        <p>{memory.sequences[memory.currentRound].display}</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {
            memory.sequences[memory.currentRound].buttons.map((button, index) => {
              return <MemoryButton label={button} memory={memory} key={index} />
            })
          }
        </div>
      </div>
    </>
  )
}

export default hot(MemoryComponent);
