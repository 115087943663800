import component from './WofComponent';
import logic from './wof-logic';

export default {
  name: 'Who\'s on First',
  component,
  logic,
}

export * from './wof-types';
