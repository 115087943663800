import { ActionObject } from "../actions";

export interface Settings {
  speedrunMode: boolean
}

const intialState: Settings = {
  speedrunMode: false
} 

export default function settingsReducer(state: Settings = intialState, action: ActionObject) {
  if(action.type === 'TOGGLE_SPEEDRUN_MODE') {
    const newState = { ...state };

    newState.speedrunMode = !newState.speedrunMode;

    return newState;
  }

  return state;
}
