import component from './CapacitorDischargeComponent';
import logic from './capacitor-logic';

export default {
  name: 'Capacitor Discharge',
  component,
  logic,
}

export * from './capacitor-types';
