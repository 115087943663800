import component from './WireSequencesComponent';
import logic from './wire-sequences-logic';

export default {
  name: 'Wire Sequences',
  component,
  logic,
}

export * from './wire-sequences-types';
