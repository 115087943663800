import { BombModule } from "../../module-sdk";
import { randomOf, randomList } from "@reverse/random";
import { WOFDisplayLabel, WOFButtonData, WOFPosition, ALL_WOF_DISPLAY_LABELS, ALL_WOF_BUTTON_LABELS, ALL_WOF_POSITIONS, WOFButtonLabel } from "./wof-types";
import { DISPLAY_MAP, BUTTON_LIST } from './wof-data';

function blankButtonData(): WOFButtonData {
  return {
    tl: 'blank',
    tr: 'blank',
    ml: 'blank',
    mr: 'blank',
    bl: 'blank',
    br: 'blank'
  };
}

class WhosOnFirstModule extends BombModule {
  public sequences: number = 3;
  public solvedSequences: number = 0;
  public display: WOFDisplayLabel = 'blank';
  public buttons: WOFButtonData = blankButtonData();

  generateNewStage() {
    // Choose a Display String
    const display = randomOf(ALL_WOF_DISPLAY_LABELS);
    const displayPosition = DISPLAY_MAP[display];

    // Prepare the button data
    const data: WOFButtonData = blankButtonData();

    // Choose a random button label.
    const randomButtonLabel = randomOf(ALL_WOF_BUTTON_LABELS);

    // Choose one value from the button list so that we have at least one valid
    // answer you can choose.
    const required = randomOf(BUTTON_LIST[randomButtonLabel]);
    // Pick 5 (and at least one will be valid)
    const randomValues = randomList(ALL_WOF_BUTTON_LABELS, 5, [required]).concat();

    // Set all the buttons
    data[displayPosition] = randomButtonLabel;
    ALL_WOF_POSITIONS.forEach((position) => {
      if (displayPosition !== position) {
        data[position] = randomValues.pop() as WOFButtonLabel;
      }
    });

    this.buttons = data;
    this.display = display
  }

  constructor() {
    super();
    this.generateNewStage();
  }

  click(position: WOFPosition) {
    // See what the display is pointing at
    const toLook = DISPLAY_MAP[this.display];

    // Filter out which buttons we have that are in the list.
    const bombButtons = (Object.keys(this.buttons) as WOFPosition[]).map(x => this.buttons[x]);
    const correctClicks = BUTTON_LIST[this.buttons[toLook]].filter((button) => {
      return bombButtons.indexOf(button) >= 0;
    });

    // Check if the button we clicked is the first entry
    const clickedAction = this.buttons[position];
    if (correctClicks[0] === clickedAction) {
      if(this.solvedSequences >= this.sequences) {
        this.disarm();
      } else {
        this.solvedSequences++;
      }
    } else {
      this.strike();
      this.solvedSequences = 0;
    }

    // Generate the next stage
    this.generateNewStage();
  }
}

export default WhosOnFirstModule;
