import { Tuple } from "../../utils/tuple";

// TYPE DEFS
export const ALL_MEMORY_DISPLAY_LABELS = Tuple('1', '2', '3', '4');
export const ALL_MEMORY_BUTTON_LABELS = Tuple('1', '2', '3', '4');
export const ALL_MEMORY_POSITIONS = Tuple(0, 1, 2, 3);

export type MemoryDisplayLabel = typeof ALL_MEMORY_DISPLAY_LABELS[number];
export type MemoryButtonLabel = typeof ALL_MEMORY_BUTTON_LABELS[number];
export type MemoryPosition = typeof ALL_MEMORY_POSITIONS[number];

export interface MemoryRound {
  display: MemoryDisplayLabel,
  buttons: MemoryButtonLabel[],
  clicked: MemoryButtonLabel | null,
  clickedPosition: MemoryPosition | null
}
