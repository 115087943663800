import React from 'react'
import styles from '../css/timer.module.css';
import clsx from 'clsx';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Store } from '../store';
import { formatTime } from '../utils/format-time';

function TimerModule({ time, strikes }: { time: number, strikes: number }) {
  return (
    <div
      className={clsx(styles.module)}
    >
      <p>
        Time: {formatTime(time)}
      </p>
      <p>
        Strikes: {strikes}
      </p>
    </div>
  )
}

export default connect(
  (state: Store) => ({
    time: state.bomb.time,
    strikes: state.bomb.strikes,
  }),
)(hot(TimerModule));
