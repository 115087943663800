import component from './MazesComponent';
import logic from './mazes-logic';

export default {
  name: 'Mazes',
  component,
  logic,
}

export * from './mazes-types';
