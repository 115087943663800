import { randomOf, randomInt, chance } from '@reverse/random';
import deepclone from 'deepclone';

import * as modules from '../modules';
import store, { Store } from '../store';
import IndicatorArray from '../utils/indicatorArray';
import { Bomb, NeedyModule, ALL_WIDGET_TYPES, ALL_INDICATOR_TYPES, IndicatorType, Port } from '../module-sdk';
import { ActionObject, tickTimer, moduleUpdate } from '../actions';
import { BombModuleType } from '../module-sdk/internal-types';
import ModuleBase from '../module-sdk/ModuleBase';
import createOnChange from '../utils/on-change';
import { shuffle } from '@reverse/array';

const initialState: Bomb = {
  started: false,
  endgame: undefined,
  time: 0,
  timeSpeed: 1,
  strikes: 0,
  modules: [],
  batteries: {
    AA: 0,
    D: 0,
    total: 0,
  },
  serial: '',
  indicators: new IndicatorArray(),
  widgets: [],
  ports: [],
};

let timerInterval: number | undefined;

export default function (state: Bomb, action: ActionObject, { info }: Store): Bomb {
  if(!state || action.type === 'RESET') {
    return initialState;
  }
  // Generate bomb base data.
  if(action.type === 'START_GAME') {
    const bombInfo = deepclone(initialState) as Bomb;

    bombInfo.time = info.time;

    // Generate batteries.

    // Generate serial number.
    // CSPELL:DISABLE
    const letters = 'ABCDEEFGHIJKLMNPQURSTUVWXZ'.split('');
    // CSPELL:ENABLE
    bombInfo.serial
      = '??#XX#'
        .replace(/./g, (char) => {
          if (char === '?') return chance(50) ? randomOf(letters) : randomInt(1, 9).toString();
          if (char === 'X') return randomOf(letters);
          if (char === '#') return randomInt(0, 9).toString();
          return '0';
        });

    // Generate indicators.
    // let indicatorCount = randomInt(0, 4);
    // bombInfo.indicators = new IndicatorArray();
    // for (let i = 0; i < indicatorCount; i++) {
      
    //   bombInfo.indicators.push({
    //     type: type,
    //     lit: chance(50),
    //   })
    // }

    // // Generate ports.
    // let portCount = randomInt(0, 4);
    // for (let i = 0; i < portCount; i++) {
    //   bombInfo.ports.push(randomOf(ALL_PORTS));
    // }

    for (let i = 0; i < 5; i++) {
      const type = randomOf(ALL_WIDGET_TYPES);

      switch (type) {
        case 'battery-holder': {
          const type = randomOf(['AA', 'D']) as 'AA' | 'D';

          bombInfo.widgets.push({
            type: 'battery-holder',
            battery: type
          });
          bombInfo.batteries[type] += type === 'AA' ? 2 : 1;
          bombInfo.batteries.total += type === 'AA' ? 2 : 1;
          break;
        }
        case 'indicator': {
          let type: IndicatorType = 'frk';

          do {
            type = randomOf(ALL_INDICATOR_TYPES);
          } while (bombInfo.indicators.has(type));

          const lit = chance(50);
          const indicator = { lit, type };

          bombInfo.indicators.push(indicator);

          bombInfo.widgets.push({
            type: 'indicator',
            indicator,
          });

          break;
        }
        case 'port-plate': {
          let availablePorts = (chance(50) ? ['parallel','serial'] : ['div-d','rj-45','stereo-rca','ps/2']) as Port[];
          let portsToAdd = [] as Port[];

          availablePorts.forEach((port) => {
            if (chance(50)) {
              portsToAdd.push(port);
              bombInfo.ports.push(port);
            }
          });

          bombInfo.widgets.push({
            type: 'port-plate',
            ports: portsToAdd,
          });

          break;
        }
        default:
      }
    }

    // Generate modules.
    bombInfo.started = true;

    bombInfo.modules.push({ isTimer: true });

    for (let i = 0; i < info.modules; i++) {
      const allowedModules = (Object.keys(modules) as BombModuleType[])
        .filter((key) => !info.vetoed.includes(key))
        .filter((key) => !(modules[key].logic.prototype instanceof NeedyModule) || info.needy);

      const type = randomOf(allowedModules) as BombModuleType;
      (ModuleBase as any)._currentBomb = bombInfo;
      const instance = new modules[type].logic() as any;

      instance[Symbol.toStringTag] = 'WrappedModule(' + type + ')';
      instance._type = type;

      const properties = new Set<string>();
      function scan(obj: any) {
        if(obj.__proto__) {
          Object.getOwnPropertyNames(obj).forEach(x => properties.add(x));
          scan(obj.__proto__);
        }
      }
      scan(instance);
      const onChange = createOnChange(() => {
        store.dispatch(moduleUpdate());
      });

      Array.from(properties).filter(x => x !== 'constructor' && x !== '_type' && x !== 'prototype').forEach((key) => {
        let value = key === 'bomb' ? bombInfo : instance[key];
        Object.defineProperty(instance, key, {
          configurable: false,
          get() {
            if (key === 'bomb') return value;

            return (typeof value === 'object' && value !== null)
              ? onChange(value)
              : value;
          }, set(v) {
            if (key === 'bomb') return;

            value = v;
            store.dispatch(moduleUpdate());
          }
        });
      });
      Object.seal(instance);

      bombInfo.modules.push({
        isTimer: false,
        type: type,
        data: instance as any,
      });
    }

    bombInfo.modules = shuffle(bombInfo.modules);

    timerInterval = setInterval(() => {
      store.dispatch(tickTimer());
    }, 1000);

    return bombInfo;
  }
  if(action.type === 'TICK_TIMER') {
    const newState = { ...state };

    newState.time--;

    if(newState.time === 0) {
      clearInterval(timerInterval);
      newState.modules.forEach(x => !x.isTimer && x.data.dispose());
      newState.endgame = {
        defused: false,
        cause: 'time',
        timeRemaining: 0,
      };
    }

    return newState;
  }
  if (action.type === 'MODULE_UPDATE' ) {
    return { ...state };
  }
  if(action.type === 'STRIKE' ) {
    const newState = { ...state };

    if (action.forceExplode) {
      newState.strikes = 3;
    } else {
      newState.strikes++;
      newState.timeSpeed += 0.25;
    }

    // Stop timer.
    clearInterval(timerInterval);

    if (newState.strikes === 3 || (info.hardcore && newState.strikes === 1) ) {
      newState.modules.forEach(x => !x.isTimer && x.data.dispose());
      newState.endgame = {
        defused: false,
        cause: action.from,
        timeRemaining: newState.time,
      };
    } else {
      // Speed up timer.
      timerInterval = setInterval(() => {
        store.dispatch(tickTimer());
      }, 1000 - 250 * newState.strikes);
    }

    return newState;
  }
  return state;
}
