import { BombModule } from "../../module-sdk";
import { Wire, ALL_WIRE_COLORS } from "./wires-types";
import { randomOf, randomInt } from "@reverse/random";

class WiresModule extends BombModule {
  public wires: Wire[];

  constructor() {
    super();

    this.wires = [];

    // Push a random amount of wires.
    let wiresToGenerate = randomInt(3, 6);
    do {
      this.wires.push({
        color: randomOf(ALL_WIRE_COLORS),
        cut: false
      });
      wiresToGenerate--;
    } while (wiresToGenerate !== 0);
  }

  cut(index: number) {
    // Ignore already cut wires
    if(this.wires[index].cut) return;

    // Get some bomb metadata to be used later
    const lastSerialDigitOdd = parseInt(this.bomb.serial.substring(5)) % 2 !== 0;

    // Check which wire should have actually been cut
    let wireToCut: number = 0;
    if (this.wires.length === 3) {
      // Check if there is zero red wires.
      if (!this.wires.map((wire) => {
        return wire.color;
      }).includes('red')) {
        wireToCut = 1;
        // Check if there is more than one blue.
      } else if (this.wires.filter((wire) => {
        return wire.color === 'blue';
      }).length > 1) {
        const occurrences = [];
        for (let i = 0; i < this.wires.length; i++) {
          if (this.wires[i].color === 'blue') {
            occurrences.push(i);
          }
        }
        wireToCut = occurrences[1];
        // Otherwise.
      } else {
        wireToCut = 2;
      }
    } else if (this.wires.length === 4) {
      // Check if there is more than one red wire and the last digit of the serial number is odd.
      if (this.wires.filter((wire) => {
        return wire.color === 'red';
      }).length > 1 && lastSerialDigitOdd) {
        const occurrences = [];
        for (let i = 0; i < this.wires.length; i++) {
          if (this.wires[i].color === 'red') {
            occurrences.push(i);
          }
        }
        wireToCut = occurrences[occurrences.length - 1];
        // Check if there are no red wires and the last wire is yellow.
      } else if (!this.wires.map((wire) => {
        return wire.color;
      }).includes('red') && this.wires[3].color === 'yellow') {
        wireToCut = 0;
        // Check if there is only one blue wire.
      } else if (this.wires.filter((wire) => {
        return wire.color === 'blue';
      }).length === 1) {
        wireToCut = 0;
        // Check if there is more than one wire.
      } else if (this.wires.filter((wire) => {
        return wire.color === 'yellow';
      }).length > 1) {
        wireToCut = 3;
        // Otherwise.
      } else {
        wireToCut = 1;
      }
    } else if (this.wires.length === 5) {
      // Check if the last wire is black and the last digit in the serial number is odd.
      if (this.wires[4].color === 'black' && lastSerialDigitOdd) {
        wireToCut = 3;
        // Check if there is one red wire and more than one yellow wire.
      } else if (this.wires.filter((wire) => {
        return wire.color === 'red';
      }).length === 1 && this.wires.filter((wire) => {
        return wire.color === 'yellow';
      }).length > 1) {
        wireToCut = 0;
        // Check if there are no black wires.
      } else if (!this.wires.map((wire) => {
        return wire.color;
      }).includes('black')) {
        wireToCut = 1;
        // Otherwise.
      } else {
        wireToCut = 0;
      }
    } else if (this.wires.length === 6) {
      // Check if there are zero yellow wires and the last digit in the serial number is odd.
      if (!this.wires.map((wire) => {
        return wire.color;
      }).includes('yellow') && lastSerialDigitOdd) {
        wireToCut = 2;
        // Check if there is one yellow wire and more than one white wire.
      } else if (this.wires.filter((wire) => {
        return wire.color === 'yellow';
      }).length === 1 && this.wires.filter((wire) => {
        return wire.color === 'white';
      }).length > 1) {
        wireToCut = 3;
        // Check if there are zero red wires.
      } else if (!this.wires.map((wire) => {
        return wire.color;
      }).includes('red')) {
        wireToCut = 5;
        // Otherwise.
      } else {
        wireToCut = 3;
      }
    }

    // Check if we cut that wire, then do the action.
    if (wireToCut === index) {
      this.disarm();
    } else {
      this.strike();
    }

    // Set the wire to cut.
    this.wires[index].cut = true;
  }
}

export default WiresModule;
