import { Tuple } from "../../utils/tuple";

export const ALL_COMPLICATED_COLORS = Tuple('white', 'white+red', 'white+blue', 'red', 'blue', 'red+blue');
export type ComplicatedWireColor = typeof ALL_COMPLICATED_COLORS[number];

export const ALL_COMPLICATED_ACTION = Tuple('cut', 'no', 'battery', 'port', 'serial');
export type ComplicatedWireAction = typeof ALL_COMPLICATED_ACTION[number];

export interface ComplicatedWire {
  color: ComplicatedWireColor;
  led: boolean;
  star: boolean;
  cut: boolean;
}
