import React from 'react';
import { hot } from 'react-hot-loader/root';
import { WOFPosition, WOFButtonLabel } from './wof-types';
import WhosOnFirstModule from './wof-logic';
// import styles from './wof.module.css';

function WhosOnFirstButton({ position, label, wof }: { position: WOFPosition, label: WOFButtonLabel, wof: WhosOnFirstModule }) {
  function buttonClick() {
    wof.click(position);
  }

  return (
    <td><button onClick={buttonClick}>{label}</button></td>
  );
}

function WhosOnFirstComponent({ whosOnFirst }: { whosOnFirst: WhosOnFirstModule }) {
  return (
    <div>
      <h1>Who's on First?</h1>
      <p>Display: {whosOnFirst.display}</p>
      <p>Stage: {whosOnFirst.solvedSequences} of {whosOnFirst.sequences}</p>
      <table>
        <tbody>
          <tr>
            <WhosOnFirstButton position='tl' label={whosOnFirst.buttons.tl} wof={whosOnFirst} />
            <WhosOnFirstButton position='tr' label={whosOnFirst.buttons.tr} wof={whosOnFirst} />
          </tr>
          <tr>
            <WhosOnFirstButton position='ml' label={whosOnFirst.buttons.ml} wof={whosOnFirst} />
            <WhosOnFirstButton position='mr' label={whosOnFirst.buttons.mr} wof={whosOnFirst} />
          </tr>
          <tr>
            <WhosOnFirstButton position='bl' label={whosOnFirst.buttons.bl} wof={whosOnFirst} />
            <WhosOnFirstButton position='br' label={whosOnFirst.buttons.br} wof={whosOnFirst} />
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default hot(WhosOnFirstComponent);
