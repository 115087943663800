import React from 'react';
import { hot } from 'react-hot-loader/root';
import WireSequencesModule from './wire-sequences-logic';
import styles from './wire-sequences.module.css';
import { WireSequencesWireData } from './wire-sequences-types';
import { capitalize } from '@reverse/string';
import clsx from 'clsx';

function WireSequencesWire({ logic, wire, index }: { logic: WireSequencesModule, wire: WireSequencesWireData, index: number }) {
  function wireClick() {
    logic.cutWire(index);
  }

  return (
    <div>
      <li onClick={wireClick} className={clsx(wire.cut && styles.cut)}>{capitalize(wire.color)} from {wire.from} to {wire.to}</li>
    </div>
  );
}

function WireSequencesComponent({ wireSequences }: { wireSequences: WireSequencesModule }) {
  return (
    <div className={styles.container}>
      <h1>Wire Sequences</h1>
      <p>Stage: {wireSequences.stage + 1} of 4</p>
      <ul>
        {
          wireSequences.wires[wireSequences.stage].map((wire, index) => {
            return <WireSequencesWire logic={wireSequences} wire={wire} index={index} key={wire.id} />
          })
        }
      </ul>

      <button onClick={() => wireSequences.nextStage()}>Next Stage</button>
    </div>
  );
}

export default hot(WireSequencesComponent);
