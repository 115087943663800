import { Tuple } from "../../utils/tuple";

// TYPE DEFS
export const ALL_KEYPAD_SYMBOLS = Tuple('balloon', 'at', 'upsidedowny', 'squigglyn', 'squidknife', 'hookn', 'leftc', 'euro', 'cursive', 'hollowstar', 'questionmark', 'copyright', 'pumpkin', 'doublek', 'meltedthree', 'six', 'paragraph', 'bt', 'smileyface', 'pitchfork', 'rightc', 'dragon', 'filledstar', 'tracks', 'ae', 'nwithhat', 'omega');
export type KeypadSymbol = typeof ALL_KEYPAD_SYMBOLS[number];

// STATE
export interface KeypadKey {
  disarmed: boolean;
  symbol: KeypadSymbol;
  index: number;
}

// ACTIONS
export function clickKeypad(index: number): KeypadAction {
  return {
    type: 'CLICK',
    index,
  };
}

export type KeypadAction = { type: 'CLICK', index: number };
