import { BombModuleType } from "../module-sdk/internal-types";
import { ActionObject } from "../actions";

export interface BombInfo {
  readonly time: number;
  readonly modules: number;
  readonly needy: boolean;
  readonly hardcore: boolean;
  vetoed: BombModuleType[];
}

const initialState: BombInfo = {
  time: 300,
  modules: 3,
  needy: false,
  hardcore: false,
  vetoed: []
};

export default function infoReducer(state: BombInfo = initialState, action: ActionObject) {
  if(action.type === 'MENU_INCREASE_TIME') {
    const newState = { ...state };

    if(state.time === 600) {
      return newState;
    }
    newState.time += 30;

    return newState;
  }
  if(action.type === 'MENU_DECREASE_TIME') {
    const newState = { ...state };

    if(newState.time === 30) {
      return newState;
    }
    newState.time -= 30;

    return newState;
  }
  if(action.type === 'MENU_INCREASE_MODULES') {
    const newState = { ...state };

    if(newState.modules === 11) {
      return newState;
    }

    newState.modules++;

    return newState;
  }
  if(action.type === 'MENU_DECREASE_MODULES') {
    const newState = { ...state };

    if(newState.modules === 3) {
      return newState;
    }

    newState.modules--;

    return newState;
  }
  if(action.type === 'MENU_TOGGLE_NEEDY') {
    const newState = { ...state };

    newState.needy = !newState.needy;
    return newState;
  }
  if(action.type === 'MENU_TOGGLE_HARDCORE') {
    const newState = { ...state };

    newState.hardcore = !newState.hardcore;
    return newState;
  }
  if(action.type === 'TOGGLE_MODULE_VETO') {
    const newState = { ...state };

    if(newState.vetoed.includes(action.module)) {
      let newVetoed = newState.vetoed.concat();
      const index = newVetoed.indexOf(action.module);
      newVetoed.splice(index, 1);

      newState.vetoed = newVetoed;
    }else {
      const newVetoed = newState.vetoed.concat();
      newVetoed.push(action.module);
      newState.vetoed = newVetoed;
    }

    return newState;
  }

  return state;
}
