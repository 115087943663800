import React, { useState, useEffect } from 'react';
import morse from 'morse';

function MorseCodeFlasher({ word, disarmed }: { word: string, disarmed: boolean }) {
  const [isOn, setIsOn] = useState<boolean>(false);
  const [encodedIndex, setEncodedIndex] = useState<number>(0);
  const [encodedWord, setEncodedWord] = useState<string>(morse.encode(word))

  useEffect(() => {
    if(!disarmed) {
      doFlash();
    }
  }, [encodedIndex]);

  function advance() {
    if(encodedIndex === encodedWord.length - 1) {
      setTimeout(() => {
        setEncodedIndex(0);
      }, 4000);
    }else {
      setEncodedIndex(encodedIndex + 1);
    }
  }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function doFlash() {
    if(encodedWord.split('')[encodedIndex] === ' ') {
      setTimeout(() => {
        advance();
      }, 1000);

      return;
    }
    setIsOn(true);

    if(encodedWord.split('')[encodedIndex] === '.') {
      setTimeout(() => {
        setIsOn(false);
      }, 200);
      setTimeout(() => {
        advance();
      }, 400);
    }else if(encodedWord.split('')[encodedIndex] === '-') {
      setTimeout(() => {
        setIsOn(false);
      }, 600);
      setTimeout(() => {
        advance();
      }, 800);
    }
  }

  return (
  <div style={{ width: '100%', height: '50px', backgroundColor: isOn ? '#efcf40' : '#774532', marginBottom: '1em', transition: 'background-color 100ms ease' }}></div>
  );
}

export default MorseCodeFlasher;
