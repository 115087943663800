import React from 'react';
import { hot } from 'react-hot-loader/root';
import { capitalize } from '@reverse/string';
import styles from './wires.module.css';
import clsx from 'clsx';
import WiresModule from './wires-logic';
import { Wire } from './wires-types';

function WireComponent({ logic, wire, index }: { wire: Wire, index: number, logic: WiresModule }) {
  function wireClick() {
    logic.cut(index);
  }

  return <li onClick={wireClick} className={clsx(wire.cut && styles.cut)}>
    {capitalize(wire.color)}
  </li>;
}

function WiresComponent({ wires }: { wires: WiresModule }) {
  return (
    <div>
      <h1>Wires</h1>
      <ul>
        {
          wires.wires.map((wire, index) => {
            return <WireComponent
              key={index}
              index={index}
              wire={wire}
              logic={wires}
            />;
          })
        }
      </ul>
    </div>
  );
}

export default hot(WiresComponent);
