import React from 'react';
import { hot } from 'react-hot-loader/root';
import * as modules from '../modules'
import { ModuleInfo } from '../module-sdk/internal-types';
import TimerModule from './TimerModule';

function ModuleRenderer({ bombModule }: { bombModule: ModuleInfo }) {
  if (bombModule.isTimer) {
    return <TimerModule />
  } else {
    const data = bombModule.data as any;
    const Comp = modules[bombModule.type].component as any;
    const Base = modules[bombModule.type].logic.BasePlateRenderer;

    return <Base module={data}>
      <Comp
        {...{ [bombModule.type]: data }}
      />
    </Base>;
  }
}

export default hot(ModuleRenderer);
