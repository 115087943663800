import React from 'react'
import styles from '../css/bomb-plate.module.css';
import { BombModule } from '../module-sdk';
import clsx from 'clsx';

function ModuleBasePlate({ module, children }: { module: BombModule, children: JSX.Element }) {
  return (
    <div
      className={clsx(styles.module, module.disarmed && styles.disarmed)}
    >
      <div className={clsx(styles.light)} />
      { children }
    </div>
  )
}

export default ModuleBasePlate;
