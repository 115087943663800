import { NeedyModule } from "../../module-sdk";

class CapacitorDischargeModule extends NeedyModule {
  public maxTime: number = 45;
  public isCharging: boolean = false;
  private chargeInterval: number|undefined;

  protected onActivate(): void {

  }

  charge() {
    if(!this.isCharging) {
      this.isCharging = true;

      clearInterval(this.chargeInterval);
      this.chargeInterval = setInterval(() => {
        if(this.time < 45) {
          this.time++;
        }
      }, 175);

      this.pauseTimer();
    }
  }
  stopCharge() {
    if(this.isCharging) {
      clearInterval(this.chargeInterval);
      this.isCharging = false;
      this.unpauseTimer();
    }
  }

  dispose() {
    super.dispose();
    clearInterval(this.chargeInterval);
  }
}

export default CapacitorDischargeModule;
