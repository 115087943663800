import { Tuple } from '../../utils/tuple';

export interface LedConfiguration {
  lights: boolean[][],
  position: KnobDirection
}

export type KnobDirection = 'up' | 'down' | 'left' | 'right';
export type LedStatus = 'on' | 'off';

export const ALL_KNOB_DIRECTIONS = Tuple('up', 'right', 'down', 'left');
