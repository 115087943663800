import { NeedyModule } from "../../module-sdk";
import { VentingGasQuestion, ALL_VENTING_GAS_QUESTIONS } from "./venting-gas-types";
import { randomOf } from "@reverse/random";

class VentingGasModule extends NeedyModule {
  public question?: VentingGasQuestion = undefined;
  public showVentingPreventsExplosions: boolean = false;

  yes() {
    if(this.question === 'detonate') {
      this.explode();
      return;
    }
    if(this.question === 'vent') {
      this.question = undefined;
      this.deactivate();
      return;
    }
  }
  no() {
    if (this.question === 'vent') {
      this.question = 'vent';
      this.showVentingPreventsExplosions = true;
      setTimeout(() => {
        this.showVentingPreventsExplosions = false;
      }, 2000);
      return;
    }
    if (this.question === 'detonate') {
      this.deactivate();
      this.question = undefined;
      return;
    }
  }

  onActivate() {
    this.question = randomOf(ALL_VENTING_GAS_QUESTIONS);
  }
  onTimeOut() {
    this.explode();
  }
}

export default VentingGasModule;
