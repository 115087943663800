import { createStore } from 'redux';
import rootReducer from './reducers';
import { BombInfo } from './reducers/info-reducer';
import { Bomb } from './module-sdk';
import { Settings } from './reducers/settings-reducer';

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

const store = createStore(
  rootReducer,
  reduxDevTools ? reduxDevTools() : undefined,
);

export default store;

export interface Store {
  bomb: Bomb;
  info: BombInfo;
  settings: Settings;
}
