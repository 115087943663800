import React from 'react';
import { hot } from 'react-hot-loader/root';
import KeypadsModule from './keypads-logic';
import styles from './keypads.module.css';
import clsx from 'clsx';
import { KeypadKey } from './keypads-types';

function Keypad({ keypad, index, keypadsModule }: { keypad: KeypadKey, index: number, keypadsModule: KeypadsModule }) {
  function keypackPress() {
    keypadsModule.press(index);
  }

  return (
    <div onClick={keypackPress} className={styles.key}>
      <div
        className={clsx(styles.strip, keypad.disarmed && styles.disarmed)}
      />
      <img
        className={styles.image}
        src={require(`./img/${keypad.symbol}.png`)}
        alt={keypad.symbol}
        draggable={false}
        key={index}
      />
    </div>
  );
}

function Keypads({ keypads }: { keypads: KeypadsModule }) {
  return (
    <>
      <h1>Keypads</h1>
      <div className={styles.wrapper}>
        {
          keypads.keypads.map((keypad, index) => {
            return (
              <Keypad keypad={keypad} index={index} keypadsModule={keypads} key={index} />
            );
          })
        }
      </div>
    </>
  );
}

export default hot(Keypads);
