import React from 'react';
import { hot } from 'react-hot-loader/root';
import PasswordsModule from './passwords-logic';
import styles from './passwords.module.css';

function PasswordsColumn({ letters, index, logic}: { letters: string[], index: number, logic: PasswordsModule }) {
  function handleUpClick() {
    logic.handleUp(index);
  }
  function handleDownClick() {
    logic.handleDown(index);
  }
  
  return (
    <div>
      <button onClick={handleUpClick}>^</button>
      <p style={{ textAlign: 'center' }}>{letters[logic.selectedIndexes[index]]}</p>
      <button onClick={handleDownClick}>V</button>
    </div>
  );
}

function PasswordsComponent({ passwords }: { passwords: PasswordsModule }) {
  return (
    <div className={styles.container}>
      <h1>Passwords</h1>
     <div style={{
       display: 'flex',
       justifyContent: 'center'
     }}>
        {
          passwords.letters.map((letterComumn, index) => {
            return <PasswordsColumn letters={letterComumn} index={index} logic={passwords} key={index} />;
          })
        }
     </div>
      <button onClick={() => passwords.handleSubmit()} style={{ marginTop: '1em' }}>Submit</button>
    </div>
  );
}

export default hot(PasswordsComponent);
