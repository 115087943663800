import ModuleBase from "./ModuleBase";
import BombModulePlate from "../components/BombModulePlate";

export default class BombModule extends ModuleBase {
  // Public Disarmed Property
  public readonly disarmed: boolean = false;

  protected disarm() {
    (this as any).disarmed = true;
  }

  public static BasePlateRenderer = BombModulePlate;
}
