import { WireSequencesColor, WireSequencesTo } from "./wire-sequences-types";

export const WIRE_SEQUENCES_DATA: { [Color in WireSequencesColor]: WireSequencesTo[] } = {
  red: [
    'C',
    'B',
    'A',
    'AC',
    'B',
    'AC',
    'ABC',
    'AB',
    'B'
  ],
  blue: [
    'B',
    'AC',
    'B',
    'A',
    'B',
    'BC',
    'C',
    'AC',
    'A'
  ],
  black: [
    'ABC',
    'AC',
    'B',
    'AC',
    'B',
    'BC',
    'AB',
    'C',
    'C'
  ]
}
