import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';

import { menuIncreaseTime, menuDecreaseTime, menuDecreaseModules, menuIncreaseModules, menuToggleHardcore, menuToggleNeedy, startGame, toggleVeto, toggleSpeedrunMode } from '../actions';
import { formatTime } from '../utils/format-time';
import { Store } from '../store';
import * as bombModules from '../modules';
import { BombModuleType } from '../module-sdk/internal-types';
import { Settings } from '../reducers/settings-reducer';
import { NeedyModule } from '../module-sdk';

const moduleTypes = Object.keys(bombModules) as BombModuleType[];

function Welcome({
  time,
  modules,
  needy,
  hardcore,
  vetoed,
  settings,
  increaseTime,
  decreaseTime,
  increaseModules,
  decreaseModules,
  toggleNeedy,
  toggleHardcore,
  toggleVeto,
  startGame,
  toggleSpeedrunMode
}: {
  time: number,
  modules: number,
  needy: boolean,
  hardcore: boolean,
  vetoed: BombModuleType[],
  settings: Settings,
  increaseTime: VoidFunction,
  decreaseTime: VoidFunction,
  increaseModules: VoidFunction,
  decreaseModules: VoidFunction,
  toggleNeedy: VoidFunction,
  toggleHardcore: VoidFunction,
  toggleVeto: any,
  startGame: VoidFunction,
  toggleSpeedrunMode: VoidFunction
}) {
  return (
    <>
      <div style={{
        padding: '10px',
        backgroundColor: '#a40000',
        border: '5px dashed #640000'
      }}>
        This game is a work in progress, and this is a very early beta. Currently, we are working on getting the game mechanics to work properly, then the UI will be improved.
      </div>

      <h1>Don't Talk and Everyone Explodes</h1>
      <p>Play a text based version of Keep Talking and Nobody Explodes in your browser. This version contains the same rules and logic as the original KTaNE.</p>

      <h2>Settings</h2>
      <label>
        <input
          type='checkbox'
          onChange={toggleSpeedrunMode}
          checked={settings.speedrunMode}
        />
        <span><strong>Speedrun Mode</strong>: Enables extra information for speedrunners.</span>
      </label>

      <h2>New Game</h2>
      <p>Time</p>
      <button onClick={decreaseTime}>
        -
      </button>
      {formatTime(time)}
      <button onClick={increaseTime}>
        +
      </button>

      <p>Modules</p>
      <button onClick={decreaseModules}>
        -
      </button>
      {modules}
      <button onClick={increaseModules}>
        +
      </button>

      <br />
      <br />
      <button onClick={toggleNeedy}>Needy: {needy ? 'On' : 'Off'}</button>
      <button onClick={toggleHardcore}>Hardcore: {hardcore ? 'On' : 'Off'}</button>

      <br />
      <br />

      <h3>Vetos</h3>
      <ul>
        {
          moduleTypes.filter((key) => {
            return !(bombModules[key].logic.prototype instanceof NeedyModule);
          }).map((key) => {
            const module = bombModules[key];
            return (
              <li>
                <label>
                  <input
                    type='checkbox'
                    value={module.name}
                    checked={vetoed.includes(key)}
                    disabled={vetoed.length === 3 && !vetoed.includes(key)}
                    onChange={() => toggleVeto(key)}
                  />
                  <span>{module.name}</span>
                </label>
              </li>
            )
          })
        }
      </ul>

      <button onClick={startGame}>Start</button>
    </>
  );
}

const mapStateToProps = (state: Store) => ({
  time: state.info.time,
  needy: state.info.needy,
  modules: state.info.modules,
  hardcore: state.info.hardcore,
  vetoed: state.info.vetoed,
  settings: state.settings
});
const mapDispatchToProps = {
  increaseTime: menuIncreaseTime,
  decreaseTime: menuDecreaseTime,
  decreaseModules: menuDecreaseModules,
  increaseModules: menuIncreaseModules,
  toggleHardcore: menuToggleHardcore,
  toggleNeedy: menuToggleNeedy,
  toggleVeto,
  startGame,
  toggleSpeedrunMode
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(Welcome));
