import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Store } from '../store';
import { Bomb as IBomb } from '../module-sdk';
import { formatTime } from '../utils/format-time';
import ModuleRenderer from './ModuleRenderer';
import { reset } from '../actions';
import getModuleName from '../module-sdk/get-module-name';
// import styles from '../css/bomb.module.css';
// import clsx from 'clsx';

function Bomb({ info, reset }: { info: IBomb, reset: VoidFunction }) {
  return (
    <>
      {
        !info.endgame
          ? <>
              <div>
                <h4>Widgets</h4>
                <ul>
                  {
                    info.widgets.map((widget, key) => {
                      switch(widget.type) {
                        case 'battery-holder':
                          if(widget.battery === 'AA') {
                            return <li key={key}>Two AA Batteries</li>
                          } else {
                            return <li key={key}>One D Battery</li>
                          }
                        case 'indicator':
                          if(widget.indicator.lit) {
                            return <li key={key}>A lit "{widget.indicator.type.toUpperCase()}" Indicator</li>
                          } else {
                            return <li key={key}>An unlit "{widget.indicator.type.toUpperCase()}" Indicator</li>
                          }
                        case 'port-plate':
                          return <>
                            <li>A port plate with:</li>
                            <ul style={{ margin: 0 }}>
                              {widget.ports.map((port, key) => {
                                return <li key={key}>{port}</li>
                              })}
                            </ul>
                          </>
                        default:
                          break;
                      }
                    })
                  }
                </ul>
              </div>
              <div>
                {
                  info.modules.map((bombModule, index) => {
                    return (
                      <ModuleRenderer bombModule={bombModule} key={index} />
                    );
                  })
                }
            </div>
          </>
          : <>
            <h1>{info.endgame.defused ? 'Defused' : 'Exploded'}</h1>
            <p>Time Remaining: {formatTime(info.endgame.timeRemaining)}</p>
            {
              info.endgame.cause === 'defused'
                ? null
                : <p>
                  Cause of Explosion: {info.endgame.cause === 'time' ? 'Time ran out' : getModuleName(info.endgame.cause)}.
                </p>
            }
            <button onClick={reset}>OK</button>
          </>
      }
    </>
  );
}

const mapStateToProps = (state: Store) => ({
  info: state.bomb
});
const mapDispatchToProps = { reset };

export default connect(mapStateToProps, mapDispatchToProps)(hot(Bomb));
