import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Store } from '../store';

import Welcome from './Welcome';
import Bomb from './Bomb';

function App({ started }: { started: boolean }) {
  return (
    <div>
      <div>
        {
          started
            ? <Bomb />
            : <Welcome />
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state: Store) => ({
  started: state.bomb.started
});

export default connect(mapStateToProps, {})(hot(App));
