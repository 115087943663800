import IndicatorArray from "../utils/indicatorArray";
import { Tuple } from "../utils/tuple";
import { BombModuleType, ModuleInfo } from './internal-types';

// todo: custom widgets
export const ALL_WIDGET_TYPES = Tuple('indicator', 'battery-holder', 'port-plate')
export type WidgetType = typeof ALL_WIDGET_TYPES[number];

export type GameEndReason = 'time' | 'defused' | BombModuleType;

export const ALL_INDICATOR_TYPES = Tuple('snd', 'clr', 'car', 'ind', 'frq', 'sig', 'nsa', 'msa', 'trn', 'bob', 'frk');
export const ALL_PORTS = Tuple('div-d', 'parallel', 'ps/2', 'rj-45', 'serial', 'stereo-rca');

export type IndicatorType = typeof ALL_INDICATOR_TYPES[number];
export type Port = typeof ALL_PORTS[number];

export type Indicator = { type: IndicatorType, lit: boolean };

export interface BatteryHolderWidget {
  type: 'battery-holder'
  battery: 'D' | 'AA';
}
export interface PortPlateWidget {
  type: 'port-plate',
  ports: Port[],
}
export interface IndicatorWidget {
  type: 'indicator',
  indicator: Indicator,
}

export type Widget = BatteryHolderWidget | PortPlateWidget | IndicatorWidget;

export interface Bomb {
  started: boolean;
  endgame?: {
    defused: boolean;
    timeRemaining: number;
    cause: GameEndReason;
  };
  time: number;
  timeSpeed: number;
  strikes: number;
  modules: ModuleInfo[];
  batteries: {
    AA: number;
    D: number;
    total: number;
  };
  serial: string;
  indicators: IndicatorArray;
  ports: Port[];
  widgets: Widget[];
}
