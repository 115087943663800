import { MenuActionObject, GameActionObject, GlobalActionObject } from "./action-types";
import { BombModuleType } from "../module-sdk/internal-types";

export function reset(): GlobalActionObject {
  return { type: 'RESET' };
}
export function init(): GlobalActionObject {
  return { type: 'INIT' };
}
export function startGame(): MenuActionObject {
  return { type: 'START_GAME' };
}
export function tickTimer(): GameActionObject {
  return { type: 'TICK_TIMER' };
}
export function moduleUpdate(): GameActionObject {
  return {
    type: 'MODULE_UPDATE',
  };
}
export function strikeBomb(from: BombModuleType, force: boolean): GameActionObject {
  return {
    type: 'STRIKE',
    from,
    forceExplode: force,
  }
}
export function menuIncreaseTime(): MenuActionObject {
  return { type: 'MENU_INCREASE_TIME' };
}
export function menuDecreaseTime(): MenuActionObject {
  return { type: 'MENU_DECREASE_TIME' };
}
export function menuIncreaseModules(): MenuActionObject {
  return { type: 'MENU_INCREASE_MODULES' };
}
export function menuDecreaseModules(): MenuActionObject {
  return { type: 'MENU_DECREASE_MODULES' };
}
export function menuToggleNeedy(): MenuActionObject {
  return { type: 'MENU_TOGGLE_NEEDY' };
}
export function menuToggleHardcore(): MenuActionObject {
  return { type: 'MENU_TOGGLE_HARDCORE' };
}
export function toggleVeto(module: BombModuleType): MenuActionObject {
  return {
    type: 'TOGGLE_MODULE_VETO',
    module
  };
}
export function toggleSpeedrunMode(): MenuActionObject {
  return {
    type: 'TOGGLE_SPEEDRUN_MODE'
  };
}
