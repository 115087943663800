import { ComplicatedWireColor, ComplicatedWireAction } from './complicated-types';

export const COMPLICATED_DATA: { [Color in ComplicatedWireColor]: ComplicatedWireAction[] } = {
  'white': [ 'cut', 'cut', 'no', 'battery' ],
  'red': [ 'serial', 'cut', 'battery', 'battery' ],
  'white+red': [ 'serial', 'cut', 'battery', 'battery' ],
  'blue': [ 'serial', 'no', 'port', 'port' ],
  'white+blue': [ 'serial', 'no', 'port', 'port' ],
  'red+blue': [ 'serial', 'port', 'serial', 'no' ]
};
