export function formatTime(time: number): string {
  const minutes = Math.floor(time / 60);
  const minutesString = minutes.toString().padStart(2, '0');
  const seconds = (time - minutes * 60).toString().padStart(2, '0');

  return `${minutesString}:${seconds}`;
}

export function unformatTime(timeString: string) {
  if(timeString.includes(':')) {
    const [minutes, seconds] = timeString.split(':');

    return parseInt(minutes) * 60 + parseInt(seconds);
  }
  return parseFloat(timeString);
}
