import { Tuple } from "../../utils/tuple";

// TYPES
export const ALL_WIRE_COLORS = Tuple('red', 'blue', 'black', 'yellow', 'white');
export type WireColor = typeof ALL_WIRE_COLORS[number];

// THE STATE
export interface Wire {
  color: WireColor;
  cut: boolean;
}
