export type WireSequencesColor = 'red' | 'blue' | 'black';
export type WireSequencesFrom = '1' | '2' | '3';
export type WireSequencesTo = 'A' | 'B' | 'C' | 'AB' | 'AC' | 'BC' | 'ABC';
export type WireSequencesStage = 0 | 1 | 2 | 3;

export interface WireSequencesWireData {
  from: WireSequencesFrom,
  to: WireSequencesTo,
  color: WireSequencesColor,
  id: number,
  cut: boolean
}

export const ALL_WIRE_SEQUENCES_COLORS: WireSequencesColor[] = ['red', 'blue', 'black'];
export const ALL_WIRE_SEQUENCES_FROM: WireSequencesFrom[] = ['1', '2', '3'];
export const ALL_WIRE_SEQUENCES_TO: WireSequencesTo[] = ['A', 'B', 'C'];
export const ALL_WIRE_SEQUENCES_STAGES: WireSequencesStage[] = [0, 1, 2, 3];
