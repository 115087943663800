import component from './ButtonComponent';
import logic from './button-logic';

export default {
  name: 'The Button',
  component,
  logic,
}

export * from './button-types';
