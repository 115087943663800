import React from 'react';
import { hot } from 'react-hot-loader/root';
import styles from './complicated.module.css';
import ComplicatedWiresModule from './complicated-logic';
import { ComplicatedWire } from './complicated-types';

function ComplicatedWireComponent({ complicatedWires, wire, index }: { complicatedWires: ComplicatedWiresModule, wire: ComplicatedWire | null, index: number }) {
  function cut() {
    complicatedWires.cut(index);
  }
  return <li onClick={cut}>
    {
      wire === null
        ? '(blank)'
        : `${wire.star ? '*' : ' '}${wire.color}${wire.led ? ' (LED)' : ' '} ${wire.cut ? '[CUT]' : ''}`
    }
  </li>
}

function ComplicatedWiresComponent({ complicatedWires }: { complicatedWires: ComplicatedWiresModule }) {
  return (
    <div className={styles.container}>
      <h1>Complicated Wires</h1>
      <ul>
        {
          complicatedWires.wires.map((wire, index) => {
            return <ComplicatedWireComponent key={index} index={index} wire={wire} complicatedWires={complicatedWires} />
          })
        }
      </ul>
    </div>
  );
}

export default hot(ComplicatedWiresComponent);
