import component from './ComplicatedWiresComponent';
import logic from './complicated-logic';

export default {
  name: 'Complicated Wires',
  component,
  logic,
}

export * from './complicated-types';
