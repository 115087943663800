import { BombModule } from "../../module-sdk";
import { WireSequencesWireData, WireSequencesStage, ALL_WIRE_SEQUENCES_FROM, ALL_WIRE_SEQUENCES_TO, ALL_WIRE_SEQUENCES_COLORS, ALL_WIRE_SEQUENCES_STAGES } from "./wire-sequences-types";
import { randomOf } from "@reverse/random";
import { WIRE_SEQUENCES_DATA } from "./wire-sequences-data";

function generateWireSequencesWire() {
  return {
    from: randomOf(ALL_WIRE_SEQUENCES_FROM),
    to: randomOf(ALL_WIRE_SEQUENCES_TO),
    color: randomOf(ALL_WIRE_SEQUENCES_COLORS),
    id: Number(Math.random().toString().substring(2)),
    cut: false
  };
}

class WireSequencesModule extends BombModule {
  public wires: WireSequencesWireData[][];
  public stage: WireSequencesStage = 0;

  constructor() {
    super();

    this.wires = [[], [], [], []];
    this.wires.forEach((sequence) => {
      for(let i = 0; i < 2; i++) {
        sequence.push(generateWireSequencesWire());
      }
    });

    this.wires[randomOf(ALL_WIRE_SEQUENCES_STAGES)].push(generateWireSequencesWire());
  }

  cutWire(index: number) {
    const wireCut = this.wires[this.stage][index];
    wireCut.cut = true;

    const allOfColor = this.wires.flat().filter((wire) => {
      return wire.color === wireCut.color;
    });

    if(!WIRE_SEQUENCES_DATA[wireCut.color][allOfColor.indexOf(wireCut)].includes(wireCut.to)) {
      this.strike();
    }
  }

  nextStage() {
    let isValid = true;

    for(let i = 0; i < this.wires[this.stage].length; i++) {
      const wireToCheck = this.wires[this.stage][i];
      const allOfColor = this.wires.flat().filter((wire) => {
        return wire.color === wireToCheck.color;
      });

      debugger;

      if(WIRE_SEQUENCES_DATA[wireToCheck.color][allOfColor.indexOf(wireToCheck)].includes(wireToCheck.to) && !wireToCheck.cut) {
        this.strike();

        isValid = false;
      }
    }

    if(isValid) {
      if(this.stage === 3) {
        this.disarm();
        return;
      }
      this.stage++;
    }
  }
}

export default WireSequencesModule;
