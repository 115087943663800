import { randomOf } from "@reverse/random";

import { NeedyModule } from "../../module-sdk";
import { KnobDirection, ALL_KNOB_DIRECTIONS, LedConfiguration } from "./knobs-types";
import { ALL_KNOB_PATTERNS } from "./knobs-data";

class KnobsModule extends NeedyModule {
  public direction: KnobDirection = 'up';
  public upDirection: KnobDirection = 'up';
  public lights?: LedConfiguration;

  rotateKnob() {
    const directionIndex = ALL_KNOB_DIRECTIONS.indexOf(this.direction);

    if(directionIndex === 3) {
      this.direction = ALL_KNOB_DIRECTIONS[0];
    }else {
      this.direction = ALL_KNOB_DIRECTIONS[directionIndex + 1];
    }
  }

  onActivate() {
    this.upDirection = randomOf(ALL_KNOB_DIRECTIONS);
    this.lights = randomOf(ALL_KNOB_PATTERNS);
  }
  onTimeOut() {
    if(this.lights && this.lights.position === this.direction) {
      this.deactivate();
    }else {
      this.strike();
    }
  }
}

export default KnobsModule;
