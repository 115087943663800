import React from 'react'
import { Bomb } from "./public-types";
import { BombModuleType } from "./internal-types";
import { strikeBomb } from "../actions";
import store from "../store";

export default abstract class ModuleBase {
  private static _currentBomb: Bomb = null as any;
  private _type: BombModuleType = null as any;
  protected strike() {
    store.dispatch(strikeBomb(this._type, false));
  }
  protected explode() {
    store.dispatch(strikeBomb(this._type, true));
  }
  protected get bomb() {
    return ModuleBase._currentBomb;
  };

  dispose() {}

  public static BasePlateRenderer: (props: { module: any, children: JSX.Element }) => JSX.Element = () => React.createElement('div', null, 'Error: No Renderer');
}
