import component from './KnobsComponent';
import logic from './knobs-logic';

export default {
  name: 'Knobs',
  component,
  logic,
}

export * from './knobs-types';
