import ModuleBase from "./ModuleBase";
import { randomInt } from "@reverse/random";
import NeedyModulePlate from "../components/NeedyModulePlate";

const needyMinActivate = 2;
const needyMaxActivate = 3;
// const needyMinActivate = 85;
// const needyMaxActivate = 95;

export default abstract class NeedyModule extends ModuleBase {
  private _timerInterval?: number;
  private _activateTimeout?: number;

  public timePaused: boolean = false;
  public time: number = -1;
  public maxTime: number = 40;

  private _activateTimer() {
    this._timerInterval = setInterval(() => {
      if (!this.timePaused) {
        if (this.time > 0) {
          this.time--;
          this.onTimeTick();
          if (this.time === 0) {
            this.onTimeOut();
          }
        }
      }
    }, 1000);
  }

  constructor() {
    super();
    this._activateTimeout = setTimeout(() => {
      this.onActivate();
      this.time = this.maxTime;
      this.timePaused = false;
      this._activateTimer();
    }, randomInt(needyMinActivate * 1000, needyMaxActivate * 1000));
  }

  protected pauseTimer() { this.timePaused = true; };
  protected unpauseTimer() { this.timePaused = false; };
  protected deactivate() {
    this.time = -1;
    clearInterval(this._timerInterval);
    // make a reactivation timer
    this._activateTimeout = setTimeout(() => {
      this.onActivate();
      this.time = this.maxTime;
      this.timePaused = false;
      this._activateTimer();
    }, randomInt(20000, 45000));
  };

  protected abstract onActivate(): void;
  protected onTimeOut(): void { this.explode(); };
  protected onTimeTick(): void { };

  dispose() {
    super.dispose();

    clearInterval(this._timerInterval);
    clearTimeout(this._activateTimeout);
  }

  public static BasePlateRenderer = NeedyModulePlate;
}
