import React from 'react';
import { hot } from 'react-hot-loader/root';
import { capitalize } from '@reverse/string';
import { SimonColor } from './simon-types';
import SimonSaysModule from './simon-logic';
// import styles from './simon.module.css';

function SimonSaysButton({ color, simon }: { color: SimonColor, simon: SimonSaysModule }) {
  function simonSaysButtonClick() {
    simon.pressButton(color);
  }

  return <button onClick={simonSaysButtonClick}>{capitalize(color)}</button>
}

function SimonSays({ simonSays }: { simonSays: SimonSaysModule }) {
  return (
    <>
      <h1>Simon Says</h1>
      <p>Sequence: {simonSays.sequence.map((color) => {
        return capitalize(color);
      }).join(', ')}</p>
      <SimonSaysButton color='blue' simon={simonSays} />
      <SimonSaysButton color='red' simon={simonSays} />
      <SimonSaysButton color='yellow' simon={simonSays} />
      <SimonSaysButton color='green' simon={simonSays} />
    </>
  );
}

export default hot(SimonSays);
