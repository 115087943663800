import { Tuple } from "../../utils/tuple";

// TYPE DEFS
export const ALL_WOF_BUTTON_LABELS = Tuple('blank', 'done', 'first', 'hold', 'left', 'like', 'middle', 'next', 'no', 'nothing', 'okay', 'press', 'ready', 'right', 'sure', 'u', 'uhhh', 'uh huh', 'uh uh', 'ur', 'wait', 'what', 'what?', 'yes', 'you', 'you\'re', 'you are', 'your');
export const ALL_WOF_DISPLAY_LABELS = Tuple(' ', 'blank', 'c', 'cee', 'display', 'first', 'hold on', 'lead', 'led', 'leed', 'no', 'nothing', 'okay', 'read', 'red', 'reed', 'says', 'see', 'their', 'there', 'they are', 'they\'re', 'ur', 'yes', 'you', 'you are', 'your', 'you\'re');
export const ALL_WOF_POSITIONS = Tuple('bl', 'br', 'tl', 'tr', 'ml', 'mr');

export type WOFButtonLabel = typeof ALL_WOF_BUTTON_LABELS[number];
export type WOFDisplayLabel = typeof ALL_WOF_DISPLAY_LABELS[number];
export type WOFPosition = typeof ALL_WOF_POSITIONS[number];

// STATE
export type WOFButtonData = {
  [Position in WOFPosition]: WOFButtonLabel
};
