import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import styles from './button.module.css';
import ButtonClass from './button-logic';

function Button({ button }: { button: ButtonClass }) {
  const [isPushed, setIsPushed] = useState<boolean>(false);
  const [holdTimeout, setHoldTimeout] = useState<number | undefined>(undefined);

  function handleDown() {
    setIsPushed(true);

    setHoldTimeout(setTimeout(() => {
      button.startHold();
    }, 250));
  }
  function handleUp() {
    if(isPushed) {
      if (button.isHoldingDown) {
        button.releaseHold();
      } else {
        button.pressButton();
      }
    }

    clearTimeout(holdTimeout);
    setIsPushed(false);
  }

  return (
    <>
      <h1>The Button</h1>
      <div className={styles.container}>
        <button
          className={styles.button}
          style={{
            background: button.color
          }}
          onMouseDown={handleDown}
          onMouseLeave={handleUp}
          onMouseUp={handleUp}
          onTouchStart={handleDown}
          onTouchEnd={handleUp}
        >
          {button.label.toUpperCase()}
        </button>
        <div
          className={styles.strip}
          style={{
            background: (button.isHoldingDown && button.stripColor) || 'black',
          }}
        />
      </div>
    </>
  );
}

export default hot(Button);
