import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader/root';
import VentingGasModule from './venting-gas-logic';
import styles from './venting-gas.module.css';

function VentingGasComponent({ ventingGas }: { ventingGas: VentingGasModule }) {

  return (
    <div className={styles.container}>
      <p>
        {
          ventingGas.showVentingPreventsExplosions
            ? 'VENTING PREVENTS EXPLOSIONS'
            : ventingGas.question
              ? (
                ventingGas.question === 'detonate'
                  ? 'DETONATE?'
                  : 'VENT GAS'
              )
              : ''
        }
      </p>
      <button onClick={useCallback(() => ventingGas.yes(), [ventingGas])}>
        YES
      </button>
      <button onClick={useCallback(() => ventingGas.no(), [ventingGas])}>
        NO
      </button>
    </div>
  );
}

export default hot(VentingGasComponent);
