import { BombModule, Bomb } from "../../module-sdk";
import { randomInt, randomOf, chance } from "@reverse/random";
import { ComplicatedWire, ALL_COMPLICATED_COLORS } from "./complicated-types";
import { COMPLICATED_DATA } from "./complicated-data";
import { shuffle } from "@reverse/array";

export function getComplicatedWireAction(wire: ComplicatedWire, bomb: Bomb): boolean {
  const action = COMPLICATED_DATA[wire.color][(wire.led ? 2 : 0) + (wire.star ? 1 : 0)];

  if (action === 'cut') {
    return true;
  } else if (action === 'no') {
    return false;
  } else if (action === 'battery') {
    return bomb.batteries.total >= 2;
  } else if (action === 'port') {
    return bomb.ports.includes('parallel');
  } else if (action === 'serial') {
    return !!bomb.serial.match(/[02468]/);
  } else {
    throw new Error('Error in Complicated Wire Data');
  }
}

class ComplicatedWiresModule extends BombModule {
  public wires: (ComplicatedWire | null)[];
  public wireActions: (boolean | null)[];

  generateComplicatedWire(): ComplicatedWire {
    return {
      color: randomOf(ALL_COMPLICATED_COLORS),
      led: chance(50),
      star: chance(50),
      cut: false,
    };
  }

  constructor() {
    super();

    let count = randomInt(3, 6);

    // Generate an array of wires
    this.wires = Array.from({ length: count }, () => this.generateComplicatedWire());
    // Pad the end with null
    this.wires = this.wires.concat(Array(6 - count).fill(null));
    // Shuffle the nulls into the array
    this.wires = shuffle(this.wires);
    // Calculate Wire Actions
    this.wireActions = this.wires.map(wire => wire ? getComplicatedWireAction(wire, this.bomb) : null);
  }

  cut(index: number) {
    const wire = this.wires[index];
    const shouldCutWire = this.wireActions[index];

    // Sanity Check
    if (!wire) return;
    if (wire.cut) return;

    // Check the wire action.
    if (!shouldCutWire) {
      this.strike();
    }

    wire.cut = true;

    // Check if we got all the wires
    const wireActionsNonNull = this.wireActions.filter(wire => wire !== null) as boolean[];
    const found = (this.wires.filter(wire => wire !== null) as ComplicatedWire[]).find((wire, i) => (!wire.cut && wireActionsNonNull[i]));

    if (!found) {
      this.disarm();
    }
  }
}

export default ComplicatedWiresModule;
