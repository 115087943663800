import { BombModule } from "../../module-sdk";
import { ALL_MORSE_FREQS, ALL_MORSE_WORDS, MorseWord, MORSE_DATA } from "./morse-types";
import { randomOf } from "@reverse/random";

class MorseCodeModule extends BombModule {
  public freqIndex: number;
  public word: MorseWord;

  constructor() {
    super();

    this.freqIndex = 0;
    this.word = randomOf(ALL_MORSE_WORDS);
  }

  getFreqString() {
    return ALL_MORSE_FREQS[this.freqIndex];
  }

  increaseFreq() {
    this.freqIndex++;
    if (this.freqIndex >= ALL_MORSE_FREQS.length) {
      this.freqIndex = 0;
    }
  }
  decreaseFreq() {
    this.freqIndex--;
    if(this.freqIndex < 0) {
      this.freqIndex = ALL_MORSE_FREQS.length - 1;
    }
  }
  transmit() {
    if(MORSE_DATA[this.word] === ALL_MORSE_FREQS[this.freqIndex]) {
      this.disarm();
    } else {
      this.strike();
    }
  }
}

export default MorseCodeModule;
