import component from './PasswordsComponent';
import logic from './passwords-logic';

export default {
  name: 'Passwords',
  component,
  logic,
}

export * from './passwords-types';
