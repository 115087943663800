import { Indicator, IndicatorType } from "../module-sdk";

export default class IndicatorArray extends Array<Indicator> {
  from(array: Indicator[]) {
    return Array.from.apply(IndicatorArray, array as any) as IndicatorArray;
  }

  hasLit(type: IndicatorType) {
    return this.find((ind) => ind.lit && ind.type === type);
  }
  hasUnlit(type: IndicatorType) {
    return this.find((ind) => !ind.lit && ind.type === type);
  }
  has(type: IndicatorType) {
    return this.find((ind) => ind.type === type);
  }
}
