import { KeypadSymbol } from "./keypads-types";

export const KEYPAD_SEQUENCES: KeypadSymbol[][] = [
  ['balloon', 'at', 'upsidedowny', 'squigglyn', 'squidknife', 'hookn', 'leftc'],
  ['euro', 'balloon', 'leftc', 'cursive', 'hollowstar', 'hookn', 'questionmark'],
  ['copyright', 'pumpkin', 'cursive', 'doublek', 'meltedthree', 'upsidedowny', 'hollowstar'],
  ['six', 'paragraph', 'bt', 'squidknife', 'doublek', 'questionmark', 'smileyface'],
  ['pitchfork', 'smileyface', 'bt', 'rightc', 'paragraph', 'dragon', 'filledstar'],
  ['six', 'euro', 'tracks', 'ae', 'pitchfork', 'nwithhat', 'omega'],
];
